<template>
  <component :is="type" v-bind="linkProps(to)">
    <slot/>
  </component>
</template>

<script>
import {isExternal} from '@/utils/validate'

export default {
  props: {
    to: {
      type: [String, Object],
      required: true
    },

    isBacklink: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isExternal() {
      return isExternal(this.to) || this.isBacklink
    },
    type() {
      if (this.isExternal) {
        return 'a'
      }
      return 'router-link'
    }
  },

  methods: {
    linkProps(to) {
      if (this.isExternal) {
        return {
          href: to,
          target: '_blank',
          rel: 'noopener'
        }
      }
      return {to}
    }
  }
}
</script>
