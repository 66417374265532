<template>
  <el-menu
    :default-active="activeMenuStr"
    mode="horizontal"
    @select="handleSelect"
  >
    <template v-for="(item, index) in topMenus">
      <el-menu-item v-if="index < visibleNumber" :key="index" :index="item.path" :style="{'--theme': theme}"
      >
        <span class="el-menu-item-icon">
        <svg-icon :icon-class="item.meta.icon"/>
        </span>
        <span class="el-menu-item-title">{{ item.meta.title }}</span>
      </el-menu-item>
    </template>

    <!-- 顶部菜单超出数量折叠 -->
    <el-submenu v-if="topMenus.length > visibleNumber" :style="{'--theme': theme}" index="more">
      <template slot="title">更多菜单</template>
      <template v-for="(item, index) in topMenus">
        <el-menu-item
          v-if="index >= visibleNumber"
          :key="index"
          :index="item.path"
        >
          <svg-icon :icon-class="item.meta.icon"/>
          {{ item.meta.title }}
        </el-menu-item>
      </template>
    </el-submenu>
  </el-menu>
</template>

<script>
import {constantRoutes} from '@/router'

// 隐藏侧边栏路由
const hideList = ['/index', '/user/profile']

export default {
  data() {
    return {
      // 顶部栏初始数
      visibleNumber: 5,
      // 当前激活菜单的 index
      currentIndex: undefined,
      activeMenuStr: '',
      routers: [],
      childrenMenus: [],
    }
  },
  computed: {
    theme() {
      return this.$store.state.settings.theme
    },
    // 顶部显示菜单
    topMenus() {
      let topMenus = []
      this.routers.map((menu) => {
        if (menu.hidden !== true) {
          // 兼容顶部栏一级菜单内部跳转
          if (menu.path === '/') {
            topMenus.push(menu.children[0])
          } else {
            topMenus.push(menu)
          }
        }
      })
      return topMenus
    },

  },
  beforeMount() {
    window.addEventListener('resize', this.setVisibleNumber)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setVisibleNumber)
  },

  mounted() {
    this.setVisibleNumber()
  },

  async created() {
    await this.getSubRouters() //top
    await this.childrenMenusMethod()  //this.routers
    await this.activeMenu()
  },

  methods: {

    // 设置子路由
    childrenMenusMethod() {

      this.routers.map((router) => {
        for (let item in router.children) {
          if (router.children[item].parentPath === undefined) {
            if (router.path === '/') {
              // router.children[item].path = '/' + router.children[item].path
            } else {
              if (!this.ishttp(router.children[item].path)) {
                // router.children[item].path = router.path + '/' + router.children[item].path
              }
            }
            router.children[item].parentPath = router.path
          }
          this.childrenMenus.push(router.children[item])
        }
      })
      this.childrenMenus = constantRoutes.concat(this.childrenMenus)
    },

    getSubRouters() {
      if (localStorage.getItem('TOPBAR_ROUTES_NEW')) {
        this.routers = JSON.parse(localStorage.getItem('TOPBAR_ROUTES_NEW'))
        return
      }

      const path = this.$route.path
      const pathList = path.substring(1, path.length).split('/')
      let pathStr = ''

      if(path.includes('heatingEnergyMonitoringSystem')) {
        pathStr = '/' + pathList[0] + '/' + pathList[1]
      }else {
       pathStr = '/' + pathList[0]
      }

      // 页面刷新时匹配获取顶部路由
      let currentRoute = this.$store.state.permission.topbarRouters.find(item => item.path === pathStr)
      this.routers = currentRoute.children || []

      if (this.routers[0].parentPath) {
        localStorage.setItem('TOPBAR_ROUTES_NEW', JSON.stringify(this.routers))
        this.$store.commit('SET_SIDEBAR_ROUTERS_NEW', this.routers)
      }
    },

    // 默认激活的菜单
    activeMenu(routeUrl = '') {
      let activePath = ''

      if (routeUrl) {
        activePath = routeUrl
      } else {
        const path = this.$route.path
        if (path !== undefined && path.lastIndexOf('/') > 0 && hideList.indexOf(path) === -1) {
          const tmpPath = path.substring(1, path.length)
          const strList = tmpPath.split('/')
          if(tmpPath.includes('heatingEnergyMonitoringSystem')) {
            activePath = '/' + strList[0] + '/' + strList[1] + '/' + strList[2]
          }else {
            activePath = '/' + strList[0] + '/' + strList[1]
          }
          if (!this.$route.meta.link) {
            this.$store.dispatch('app/toggleSideBarHide', false)
          }
        } else if (!this.$route.children) {
          activePath = path
          this.$store.dispatch('app/toggleSideBarHide', true)
        }
      }

      const currentMenuId = this.routers.find(item => item.path === activePath).menuId
      this.$emit('send-top-menuId', currentMenuId)

      this.activeRoutes(activePath)
      this.activeMenuStr = activePath
    },

    // 根据宽度计算设置显示栏数
    setVisibleNumber() {
      const width = document.body.getBoundingClientRect().width / 3
      this.visibleNumber = parseInt(width / 85)
    },
    // 菜单选择事件
    handleSelect(key, keyPath) {
      this.currentIndex = key
      const route = this.routers.find(item => item.path === key)

      if (this.ishttp(key)) {
        // http(s):// 路径新窗口打开
        window.open(key, '_blank')
      } else if (!route || !route.children) {
        // 没有子路由路径内部打开
        this.$router.push({path: key})
        this.$store.dispatch('app/toggleSideBarHide', true)
      } else {
        // 显示左侧联动菜单
        this.activeMenu(key)
        this.$store.dispatch('app/toggleSideBarHide', false)
      }
    },


    // 当前激活的路由
    activeRoutes(key) {
      let routes = []

      if (this.childrenMenus && this.childrenMenus.length > 0) {
        this.childrenMenus.map((item) => {

          if (key === item.parentPath || (key === 'index' && '' === item.path)) {
            routes.push(item)
          }
        })
      }

      if (routes.length > 0) {
        this.$store.commit('SET_SIDEBAR_ROUTERS_NEW', routes)
        localStorage.setItem('SIDEBAR_ROUTERS_NEW', JSON.stringify(routes))
      } else {
        this.$store.dispatch('app/toggleSideBarHide', true)
      }
    },

    ishttp(url) {
      return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1
    }
  },
}
</script>

<style lang="scss">
.topmenu-container.el-menu--horizontal > .el-menu-item {
  float: left;
  height: 50px !important;

  line-height: 50px !important;
  color: #999093;
  padding: 0 5px !important;
  margin: 0 10px !important;
}

.topmenu-container.el-menu--horizontal > .el-menu-item.is-active, .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid #{'var(--theme)'};
  color: #303133;
}

/* submenu item */
.topmenu-container.el-menu--horizontal > .el-submenu .el-submenu__title {
  float: left;
  height: 50px !important;
  line-height: 50px !important;
  color: #999093;
  padding: 0 5px !important;
  margin: 0 10px !important;
}
</style>
