<template>
  <div class="wrapper">
    <i class="el-icon-question" @click="open"></i>
    <el-image 
      ref="elImage"
      style="width: 100px; height: 100px; display: none;"
      :src="url" 
      :preview-src-list="srcList">
    </el-image>
    <el-drawer
      :visible.sync="drawer"
      destroy-on-close
      :wrapperClosable="!editing"
      :close-on-press-escape="false"
      @close="cancel"
    >
      <div slot="title">
        <span>帮助页面</span>
        <el-link v-hasPermi="['system:doc:edit']" v-if="!editing" icon="el-icon-edit" class="edit-btn" @click="edit">编辑</el-link>
      </div>
      <div style="padding: 0px 20px;">
        <template v-if="!editing">
          <p v-if="content.length != 0" class="content" v-html="content" @click="clickContent"></p>
          <p v-else style="text-align: center; font-size: 14px; color: #999;">暂无内容</p>
        </template>
        <template v-else>
          <div style="margin-bottom: 20px;">
            <el-button type="primary" @click="save">保存</el-button>
            <el-popconfirm
              style="margin-left: 10px;"
              @confirm="cancel"
              title="确定不保存吗？"
            >
              <el-button slot="reference">取消</el-button>
            </el-popconfirm>
          </div>
          <editor v-model="content" @getFileId="getFileId" type="url" :min-height="192" :height="700" />
        </template>
      </div>
    </el-drawer>
  </div>
</template>
<script>
var savedContent = '';
import request from '@/utils/request'
export default {
  props: [],
  data() {
    return {
      editing: false,
      drawer: false,
      content: '',
      id: '',
      remark: '',
      url: '',
      srcList: []
    }
  },
  methods: {
    clickContent(e) {
      if (e.target instanceof HTMLImageElement) {
        let imgEl = this.$refs.elImage.$el.querySelector('img')
        this.url = e.target.src;
        imgEl.click()
      }
    },
    save() {
      request({
        url: `/revenue/operatorManual`,
        method: 'post',
        data: {
          "id": this.id,
          "path": this.path,
          "content": this.content,
          "remark": this.remark
        }
      }).then(res => {
        if (res.code == 200) {
          this.$message.success('已保存')
          savedContent = this.content
          // 图片列表
          let srcList = this.matchSrc(this.content)
          this.url = srcList[0]
          this.srcList = srcList
        }
      }).finally(() => {
        this.editing = false
      })
    },
    cancel() {
      this.content = savedContent
      this.editing = false
    },
    edit() {
      this.editing = true
    },
    getFileId(data) {

    },
    matchSrc(content) {
      let all = content.matchAll(/<img src="(https:\/\/.*?\.(jpg|png))">/g)
      return [...all].map(img => img[1])
    },
    open() {
      this.drawer = true;
      this.content = '';
      this.id = null;
      request({
        url: '/revenue/operatorManual',
        method: 'get',
        params: {
          path: this.path
        }
      }).then(res => {
        if (res.code == 200) {
          this.content = res.data.content
          this.id = res.data.id
          this.remark = res.data.remark
          savedContent = res.data.content
          // 图片列表
          let srcList = this.matchSrc(this.content)
          this.url = srcList[0]
          this.srcList = srcList
        }
      }).catch(err => {
        err.handleReject({showErrorMsg: false})
      })
    }
  },
  computed: {
    path() {
      return this.$route.path
    },
    manualsEnable() {
      return this.$auth.hasRole("admin")
    },
  }
}
</script>
<style lang="scss" scoped>

.el-icon-question {
  position: fixed;
  right: 40px;
  bottom: 40px;
  font-size: 40px;
  color: #174592;
  margin: 12px 5px 0 0;
}
@media screen and (max-width: 1530px) {
  .el-icon-question {
    right: 10px;
    // bottom: 10px;
    font-size: 30px;
  }
}

::v-deep .content {
  margin: 0;
  img {
    width: 100%;
  }
  p, h1, h2, h3, h4, h5 {
    margin: 0;
  }
}

.edit-btn {
  margin-left: 20px;
  font-size: 14px;
  color: #174391;
}
</style>