import { render, staticRenderFns } from "./SidebarItem.vue?vue&type=template&id=5e58054b&scoped=true"
import script from "./SidebarItem.vue?vue&type=script&lang=js"
export * from "./SidebarItem.vue?vue&type=script&lang=js"
import style0 from "./SidebarItem.vue?vue&type=style&index=0&id=5e58054b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_vue-template-compiler@2.6.12_webpack@5.96.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e58054b",
  null
  
)

export default component.exports